import { FC, ReactNode } from 'react'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import { jaJP } from '@mui/x-data-grid'
import { jaJP as coreJaJP } from '@mui/material/locale'
import { red } from '@mui/material/colors'
//https://mui.com/x/react-data-grid/getting-started/#typescript
import type {} from '@mui/x-data-grid/themeAugmentation'

type MuiThemeProviderProps = {
    children: ReactNode
}

const theme = responsiveFontSizes(
    createTheme(
        {
            typography: {
                button: {
                    textTransform: 'none',
                },
                h1: {
                    fontSize: '2rem',
                },
                h2: {
                    fontSize: '1.5rem',
                },
                h3: {
                    fontSize: '1.25rem',
                },
                h4: {
                    fontSize: '1rem',
                },
                h5: {
                    fontSize: '0.75rem',
                },
                // h6: {
                //     fontSize: '0.5rem',
                // },
                subtitle1: {
                    color: '#444444',
                },
            },
            components: {
                MuiLink: {
                    defaultProps: {
                        underline: 'hover',
                    },
                },
            },
            palette: {
                primary: {
                    main: '#0879c2',
                },
                secondary: {
                    main: '#acd4ec',
                },
                error: {
                    main: red.A400,
                },
                background: {
                    default: '#fefefe',
                },
                text: {
                    primary: '#333333',
                },
            },
        },
        jaJP, // x-data-grid translations
        coreJaJP, // core translations
    ),
)

export const MuiThemeProvider: FC<MuiThemeProviderProps> = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
