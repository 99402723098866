import axios from 'axios'

export const genericAxiosFetcher = (url: string) =>
    axios
        .get(url, { withCredentials: true })
        .then((res) => res.data)
        .catch((error) => {
            const status = error.response ? error.response.status : null
            if (status !== 401) {
                return Promise.reject(error)
            }
            goLoginPage()
            return Promise.resolve()
        })
export const API_BASE_URL: string = (() => {
    const ret = process.env.NEXT_PUBLIC_API_BASE_URL
    if (ret) {
        return ret
    } else {
        return 'http://localhost:8000'
    }
})()

export const HASURA_BASE_URL: string = (() => {
    const ret = process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL
    if (ret) {
        return ret
    } else {
        return 'http://localhost:8080'
    }
})()

export const loginPageUrl = `${API_BASE_URL}/api/a/login/?next=${
    typeof window !== 'undefined' && window.location.hostname ? window.location.href : ''
}`
const goLoginPage = () => {
    window.location.href = loginPageUrl
}
