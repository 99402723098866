import { useRecoilValue } from 'recoil'
import packageJson from '../../package.json'
import { loginUserState } from '../common/recoil'
import { useSendAppLogMutation } from '../generated/graphql'

export interface Logger {
    error(message: string, detail?: any): void

    info(message: string, detail?: any): void

    warn(message: string, detail?: any): void

    debug(message: string, detail?: any): void
}

export const useLogger = (): Logger => {
    const loginUser = useRecoilValue(loginUserState)
    const [insertLog] = useSendAppLogMutation()

    const postLog = (level: string, message: string, detail?: any) => {
        insertLog({
            variables: {
                appName: packageJson.name,
                userOrganizationId: loginUser.user_organization_id,
                level,
                message,
                detail,
            },
        })
    }

    return {
        error(message: string, detail?: any) {
            postLog('error', message, detail)
            console.error(message)
        },

        info(message: string, detail?: any) {
            postLog('info', message, detail)
            console.info(message)
        },

        warn(message: string, detail?: any) {
            postLog('warn', message, detail)
            console.warn(message)
        },

        debug(message: string, detail?: any) {
            postLog('debug', message, detail)
            console.debug(message)
        },
    }
}
