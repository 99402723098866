import dayjs from 'dayjs'

export type TransportTask = {
    id?: string //新規作成の場合はmutationするまで存在しない
    version?: number
    requested_date?: string
    transport_type?: string
    transport_type_memo?: string | null
    ownership_of_cargo?: string
    task_status?: string
    shipped_location_id?: string
    shipped_postal_code?: string | null
    shipped_address?: string | null
    shipped_company_name?: string | null
    shipped_department_name?: string | null
    shipped_location_staff_name?: string | null
    shipped_location_staff_tel?: string | null
    shipped_date?: string
    shipped_time?: string | null
    shipped_has_helper?: string | null
    shipped_memo?: string | null
    arrived_location_id?: string
    arrived_postal_code?: string | null
    arrived_address?: string | null
    arrived_company_name?: string | null
    arrived_department_name?: string | null
    arrived_location_staff_name?: string | null
    arrived_location_staff_tel?: string | null
    arrived_date?: string
    arrived_time?: string | null
    arrived_has_helper?: string | null
    arrived_memo?: string | null
    manager_id?: string
    operator_id?: string
    requested_by_id?: string

    //以下、外部キーの表示名・フォーム選択のためのオプショナルフィールド
    requested_by?: string
    operator?: string
    manager?: string
    shipped_location?: string
    arrived_location?: string

    nile_operation_transporttaskitems: {
        transport_item_id?: string
        item_name?: string
        quantity?: string
        unit?: string
        unit_memo?: string
        serial_number?: string
        serial_number_memo?: string
        nileworks_dwg_code?: string
    }[]
}

//TransportTaskを初期化したインスタンス(object)
export const defaultTransportTask: TransportTask = {
    arrived_date: '',
    arrived_has_helper: '',
    arrived_location_id: '',
    arrived_postal_code: '',
    arrived_address: '',
    arrived_company_name: '',
    arrived_department_name: '',
    arrived_location_staff_name: '',
    arrived_location_staff_tel: '',
    manager_id: '',
    operator_id: '',
    ownership_of_cargo: '',
    requested_by_id: '',
    requested_date: dayjs().format('YYYY-MM-DD'),
    shipped_date: '',
    shipped_has_helper: '',
    shipped_location_id: '',
    shipped_postal_code: '',
    shipped_address: '',
    shipped_company_name: '',
    shipped_department_name: '',
    shipped_location_staff_name: '',
    shipped_location_staff_tel: '',
    task_status: '',
    transport_type: '',
    nile_operation_transporttaskitems: [
        {
            transport_item_id: '',
            quantity: '',
            unit: '',
            unit_memo: '',
            serial_number: '',
            serial_number_memo: '',
        },
    ],
}
