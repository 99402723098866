import BatteryFullIcon from '@mui/icons-material/BatteryFull'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import FlightIcon from '@mui/icons-material/Flight'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import Search from '@mui/icons-material/Search'
import PlaceIcon from '@mui/icons-material/Place'
import ViewListIcon from '@mui/icons-material/ViewList'
import ConstructionIcon from '@mui/icons-material/Construction'
import RouteIcon from '@mui/icons-material/Route'
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { FC } from 'react'
import { ListAlt } from '@mui/icons-material'
import { useRouter } from 'next/router'
import { useDrawer } from '../../common/useDrawer'
import { anchor, drawerWidth } from './Consts'
import { Profile } from './Profile'

interface MenuListItemProps {
    text: string
    link: string
    isDrawerOpen: boolean
}

const MenuListItem = (props: MenuListItemProps) => {
    const router = useRouter()
    return (
        <ListItem key={0} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                sx={{
                    justifyContent: props.isDrawerOpen ? 'initial' : 'center',
                }}
                onClick={() => router.push(props.link)}
            >
                <ListItemIcon>
                    <ConstructionIcon />
                </ListItemIcon>
                <ListItemText
                    primary={'(開発中)'}
                    secondary={<>{props.text}</>}
                    sx={{ color: '#555555', fontSize: '0.8rem' }}
                />
            </ListItemButton>
        </ListItem>
    )
}

export const DrawerItems: FC = () => {
    const { breakpoints } = useTheme()
    const xs = useMediaQuery(breakpoints.only('xs'))
    const { isDrawerOpen, toggleDrawer } = useDrawer()
    const router = useRouter()

    return (
        <Box
            sx={{ width: drawerWidth }}
            role="presentation"
            onClick={toggleDrawer(anchor, isDrawerOpen && !xs)}
            onKeyDown={toggleDrawer(anchor, isDrawerOpen && !xs)}
        >
            <List dense>
                {[
                    {
                        text: '機材検索',
                        path: '/list',
                        icon: <ViewListIcon />,
                    },
                    {
                        text: 'T20飛行履歴',
                        path: '/flights/latest/',
                        icon: <FlightIcon />,
                    },
                    {
                        text: 'T20リアルタイム監視',
                        path: '/realtime/list/',
                        icon: <HourglassFullIcon />,
                    },
                    {
                        text: 'T20バッテリー状況',
                        path: '/battery/',
                        icon: <BatteryFullIcon />,
                    },
                    {
                        text: 'T20バッテリー検索',
                        path: '/battery/search',
                        icon: <Search />,
                    },
                    {
                        text: 'T20飛行経路生成結果',
                        path: '/mission/mission-generated',
                        icon: <RouteIcon />,
                    },
                    {
                        text: '輸送場所マスタ',
                        path: '/transport/location/',
                        icon: <PlaceIcon />,
                    },
                    {
                        text: '輸送品目マスタ',
                        path: '/transport/item/',
                        icon: <BusinessCenterIcon />,
                    },
                    {
                        text: '輸送タスク',
                        path: '/transport/task/',
                        icon: <LocalShippingIcon />,
                    },
                    {
                        text: '機体納入場所一覧',
                        path: '/transport/location/machine',
                        icon: <ListAlt />,
                    },
                ].map(({ text, path, icon }) => (
                    <ListItem key={path} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                justifyContent: isDrawerOpen ? 'initial' : 'center',
                            }}
                            onClick={() => router.push(path)}
                        >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={text}
                                sx={{ color: '#555555' }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List dense>
                <MenuListItem
                    text="JZ運航監視ダッシュボード"
                    link="/jz/monitoring/dashboard"
                    isDrawerOpen={isDrawerOpen}
                />
                <MenuListItem
                    text="JZ飛行履歴"
                    link="/jz/flight/list"
                    isDrawerOpen={isDrawerOpen}
                />
                <MenuListItem
                    text="JZ Flight Analyzer"
                    link={process.env.NEXT_PUBLIC_FLIGHT_ANALYZER_SERVER_URL || ''}
                    isDrawerOpen={isDrawerOpen}
                />
            </List>
            <Divider />
            <List dense>
                <Profile />
            </List>
        </Box>
    )
}
