import { atom } from 'recoil'
import { defaultTransportTask, TransportTask } from '../components/transport/task/TransportTask'
import { AllMachineBatteryQuery } from '../generated/graphql'

export const feedUpdatedState = atom({
    key: 'feedUpdatedState',
    default: 0,
})

export const historyEventTypeState = atom({
    key: 'historyEventTypeState',
    default: {
        support: true,
        sales: true,
        maintenance: true,
        move: true,
        etc: true,
        jig: false,
        flight: false,
        pairing: false,
        transport: true,
    },
})

export const loginUserState = atom({
    key: 'loginUserState',
    default: {
        username: '',
        user_organization_id: '',
    },
})

export const targetEditTask = atom<TransportTask>({
    key: 'targetEditTask',
    default: defaultTransportTask,
})

export const transportTaskPageIndex = atom<number>({
    key: 'transportTaskPageIndex',
    default: 0,
})

export const allBatteryCache = atom<AllMachineBatteryQuery>({
    key: 'allBatteryCache',
    default: {
        nile_machine_battery: [],
    },
})

export const drawerOpen = atom<boolean>({
    key: 'drawerOpen',
    default: true,
    effects: [({ setSelf }) => setSelf(window.innerWidth > 600)],
})
