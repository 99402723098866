import { Container, Typography, ContainerProps as MuiContainerProps } from '@mui/material'
import { FC } from 'react'

type ContainerProps = MuiContainerProps & {
    description?: string
    title: string
}

export const PageContainer: FC<ContainerProps> = ({ children, title, description }) => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h2" component="h1" sx={{ marginY: 2, fontWeight: 'bold' }}>
                {title}
            </Typography>

            {description && (
                <Typography
                    variant="subtitle1"
                    sx={{ marginY: 1, wordBreak: 'break-word', marginBottom: 3 }}
                >
                    {description}
                </Typography>
            )}

            {children}
        </Container>
    )
}
