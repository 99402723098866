import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import FaceIcon from '@mui/icons-material/Face'
import { FC, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { loginUserState } from '../../common/recoil'
import { useGetUserInfoQuery } from '../../generated/graphql'

export const Profile: FC = () => {
    const loginUser = useRecoilValue(loginUserState)
    const [userFullName, setUserFullName] = useState('')

    useGetUserInfoQuery({
        variables: { username: loginUser.username },
        onCompleted: (data) => {
            setUserFullName(data?.auth_user[0].last_name + data?.auth_user[0].first_name)
        },
    })

    return (
        <ListItem key="profile">
            <ListItemIcon key="icon">
                <FaceIcon />
            </ListItemIcon>
            <ListItemText key="text" primary={userFullName} secondary={loginUser.username} />
        </ListItem>
    )
}
