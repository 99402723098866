import {
    AppBar as MuiAppBar,
    AppBarProps as MuiAppBarProps,
    IconButton,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import { FC } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { useDrawer } from '../../common/useDrawer'
import { anchor, drawerWidth, environment } from './Consts'
import { APP_NAME } from '@/common/AppNameConst'

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

export const Header: FC = () => {
    const { breakpoints } = useTheme()
    const xs = useMediaQuery(breakpoints.only('xs'))
    const { isDrawerOpen, toggleDrawer } = useDrawer()

    return (
        <AppBar position={xs ? 'absolute' : 'fixed'}>
            <Toolbar key={anchor}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(anchor, !isDrawerOpen)}
                >
                    <MenuIcon />
                    <Typography
                        variant="h3"
                        noWrap
                        component="div"
                        sx={{ fontWeight: 'bold', ml: 2 }}
                    >
                        {APP_NAME} ({environment})
                    </Typography>
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}
