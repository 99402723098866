import { useRecoilState } from 'recoil'
import { drawerOpen } from './recoil'

export const useDrawer = () => {
    const [isDrawerOpen, setDrawerState] = useRecoilState(drawerOpen)

    const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setDrawerState(open)
    }

    return {
        isDrawerOpen,
        toggleDrawer,
    }
}
