import { ErrorBoundary } from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { FC, ReactNode, useCallback } from 'react'
import { SWRConfig } from 'swr'
import * as StackTrace from 'stacktrace-js'

import { useLogger } from './Logger'

type ErrorBoundaryRootProps = {
    children: ReactNode
}

export const ErrorBoundaryRoot: FC<ErrorBoundaryRootProps> = ({ children }) => {
    const router = useRouter()
    const { error: sendError } = useLogger()

    // イベントハンドラ、ApolloClientで検知したエラーを捕捉し、nile_operation_applogに登録
    window.onerror = async (event, source, lineno, colno, error) => {
        if (error) {
            const stackTraces = await StackTrace.fromError(error)
            sendError(event.toString(), {
                path: router.pathname,
                stack: stackTraces,
                source,
                lineno,
                colno,
            })
        }
    }

    // React内部のエラーを捕捉し、nile_operation_applogに登録
    const errorHandler = useCallback(
        async (err: Error) => {
            const stackTraces = await StackTrace.fromError(err)
            sendError(err.message, {
                path: router.pathname,
                stack: stackTraces,
            })
        },
        [sendError, router.pathname]
    )

    return (
        <ErrorBoundary onError={errorHandler}>
            <SWRConfig
                value={{
                    onError: (error) => {
                        // SWRで検知したエラーを捕捉し、nile_operation_applogに登録
                        if (error.status !== 403 && error.status !== 404) {
                            sendError('SWR Error', error)
                        }
                    },
                }}
            >
                {children}
            </SWRConfig>
        </ErrorBoundary>
    )
}
