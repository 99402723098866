import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split, from } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import jwtDecode from 'jwt-decode'
import 'leaflet/dist/leaflet.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { FC, Suspense, useState } from 'react'
import { RecoilRoot, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { SnackbarProvider } from 'notistack'
import { API_BASE_URL, genericAxiosFetcher, HASURA_BASE_URL } from '../common/axios'
import { MuiThemeProvider } from '../common/MuiThemeProvider'
import { loginUserState } from '../common/recoil'
import { Layout } from '../components/layout'
import '../style/default.css'
import { GoogleAnalytics } from '../components/GoogleAnalytics'
import { ErrorBoundaryRoot } from '../common/ErrorBoundaryRoot'
import { APP_NAME, APP_NAME_EN } from '@/common/AppNameConst'

type decodedJwt = {
    username: string
    user_organization_id: string
    exp: number
}

/**
 * ログインしている場合のみコンポーネントを表示
 */
const ComponentWithLogin: FC<AppProps> = ({ Component, pageProps }) => {
    const setLoginUser = useSetRecoilState(loginUserState)
    const [jwt, setJwt] = useState<string>('')

    const { error } = useSWR(
        jwt === '' ? API_BASE_URL + '/api/v3/accounts/generate_jwt/' : null,
        genericAxiosFetcher,
        {
            onSuccess: (data) => {
                const decoded: decodedJwt = jwtDecode(data.token)
                setLoginUser({
                    username: decoded.username,
                    user_organization_id: decoded.user_organization_id,
                })
                setJwt(data.token)
            },
        }
    )

    if (jwt === '') {
        return (
            <div>
                <button
                    onClick={() => {
                        // eslint-disable-next-line no-restricted-globals
                        location.reload()
                    }}
                >
                    Reload
                </button>
                <br />
                {error?.message}
            </div>
        )
    }

    const httpLink = new HttpLink({
        uri: HASURA_BASE_URL + '/v1/graphql',
        headers: { authorization: `Bearer ${jwt}` },
        fetch: (url, options) => {
            const { operationName } = JSON.parse(options?.body as string)
            return fetch(`${url}?app=${APP_NAME_EN}&opname=${operationName}`, options)
        },
    })

    const wsLink = new GraphQLWsLink(
        createClient({
            url:
                HASURA_BASE_URL.replace(/http:\/\//, 'ws://').replace(/https:\/\//, 'wss://') +
                '/v1/graphql',
            connectionParams: {
                headers: { authorization: `Bearer ${jwt}` },
            },
        })
    )

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            )
        },
        wsLink,
        httpLink
    )

    // ApolloClientのエラーをrethrowして、ErrorBoudaryRoot内でエラーを再catch,送信する
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(() => {
                // TODO: 例外を投げるとonErrorにいかないので、投げないようにしたい。
                // throw Error(JSON.stringify({ error: 'graphQLError', ...value }))
            })
        }
        if (networkError) {
            // throw Error(JSON.stringify({ error: 'networkError', ...networkError }))
        }
    })

    const apolloClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([errorLink, splitLink]),
        name: 'react-web-client',
        version: '1.3',
        queryDeduplication: false,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-first',
            },
        },
    })

    return (
        <ApolloProvider client={apolloClient}>
            <ErrorBoundaryRoot>
                <Suspense fallback={<>loading...</>}>
                    <MuiThemeProvider>
                        <SnackbarProvider>
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        </SnackbarProvider>
                    </MuiThemeProvider>
                </Suspense>
            </ErrorBoundaryRoot>
        </ApolloProvider>
    )
}

/**
 * Appルート
 */
const App = (props: AppProps): JSX.Element => {
    return (
        <RecoilRoot>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no, viewport-fit=cover"
                />
                <title>{APP_NAME}</title>
            </Head>
            <GoogleAnalytics />
            <ComponentWithLogin {...props} />
        </RecoilRoot>
    )
}

export default App
