import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { loginUserState } from '../common/recoil'

/** バッテリー状態検索系イベント */
type BatteryEvent =
    | 'バッテリー状態検索'
    | 'バッテリー状態フィード登録'
    | 'バッテリー状態フィード更新'
    | 'バッテリー状態フィード削除'
    | 'バッテリー状態シリアル番号更新'
    | 'バッテリーマスタ追加'
    | 'バッテリー所有者更新'

/** 機材検索系イベント */
type MachineEvent = '機材検索' | '機材フィード登録' | '機材フィード削除'

/** 飛行履歴系イベント */
type FlightEvent =
    | '飛行履歴ACリンク押下'
    | '飛行履歴ACイベントリンク押下'
    | '飛行履歴TBイベントリンク押下'
    | '飛行履歴BSイベントリンク押下'
    | '飛行履歴CAイベントリンク押下'
    | '飛行履歴ACログリンク押下'
    | '飛行履歴TBログリンク押下'
    | '飛行履歴日付リンク押下'
    | '飛行履歴ユーザリンク押下'
    | '飛行履歴組織名リンク押下'
    | '飛行履歴圃場名リンク押下'
    | '飛行履歴FVリンク押下'
    | '飛行履歴DBリンク押下'
    | '飛行履歴Docomoリンク押下'
    | '飛行履歴MAVLinkボタン押下'
    | '飛行履歴Px4log解析ボタン押下'

/** リアルタイム監視系イベント */
type RealtimeEvent = 'リアルタイム監視検索'

/** バッテリー状況系 */
type BatteryStatusEvent = 'バッテリー状況シリアル番号リンク押下'

/** 輸送場所マスタ系 */
type TransportLocationEvent = '輸送場所マスタ詳細押下'

/** 輸送品目マスタ系 */
type TransportItemEvent = '輸送品目マスタ詳細押下'

/** 輸送タスク系 */
type TransportTaskEvent =
    | '輸送タスクレコード閲覧'
    | '輸送タスクレコード編集'
    | '輸送タスクレコードコピー'
    | '輸送タスク新規作成'
    | '輸送タスクヘルプ押下'
    | '輸送タスク保存'
    | '輸送タスク詳細から編集'

// 計測対象のイベント
type EventName =
    | BatteryEvent
    | MachineEvent
    | FlightEvent
    | RealtimeEvent
    | BatteryStatusEvent
    | TransportLocationEvent
    | TransportItemEvent
    | TransportTaskEvent

export type Event = {
    eventName: EventName
    params?: unknown
}

export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || ''

export const existsGaId = GA_ID !== ''

export const useGoogleAnalytics = () => {
    const loginUser = useRecoilValue(loginUserState)

    const sendEvent = useCallback(
        ({ eventName, params }: Event) => {
            if (!existsGaId) {
                return
            }
            window.gtag('event', eventName, {
                username: loginUser.username,
                userAgent: window.navigator.userAgent,
                params: JSON.stringify(params),
            })
        },
        [loginUser.username]
    )

    const sendPageview = useCallback(
        (path: string) => {
            if (!existsGaId) {
                return
            }
            window.gtag('config', GA_ID, {
                page_path: path,
                username: loginUser.username,
            })
        },
        [loginUser.username]
    )

    return {
        sendEvent,
        sendPageview,
    }
}
