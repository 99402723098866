import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect, useMemo } from 'react'
import { GA_ID, existsGaId, useGoogleAnalytics } from '../lib/Gtag'

export const GoogleAnalytics: FC = () => {
    const router = useRouter()

    const { sendPageview } = useGoogleAnalytics()

    // ページ遷移時に、表示ページをGoogleAnalyticsに送信
    useEffect(() => {
        const handleRouteChange = (url: string) => {
            sendPageview(url)
        }

        router.events.on('routeChangeComplete', handleRouteChange)
        router.events.on('hashChangeComplete', handleRouteChange)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('hashChangeComplete', handleRouteChange)
        }
    }, [router.events, sendPageview])

    const script = useMemo(
        () => (
            <>
                <Script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
                />
                <Script
                    id="gtag-init"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GA_ID}', {
  page_path: window.location.pathname,
});
`,
                    }}
                />
            </>
        ),
        []
    )

    return <>{existsGaId && script}</>
}
