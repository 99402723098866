import {
    Box,
    useMediaQuery,
    Drawer as TemporaryDrawer,
    Theme,
    CSSObject,
    Toolbar,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

import { FC } from 'react'
import { useDrawer } from '../../common/useDrawer'
import { anchor, drawerWidth } from './Consts'
import { DrawerItems } from './DrawerItems'

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const PermanentDrawer = styled(TemporaryDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
)

export const Drawer: FC = () => {
    const { breakpoints } = useTheme()
    const xs = useMediaQuery(breakpoints.only('xs'))
    const { isDrawerOpen, toggleDrawer } = useDrawer()

    return (
        <Box component="nav">
            {xs && (
                <TemporaryDrawer
                    anchor={anchor}
                    open={isDrawerOpen}
                    onClose={toggleDrawer(anchor, false)}
                    sx={{
                        '& .TemporaryDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    variant="temporary"
                >
                    <DrawerItems />
                </TemporaryDrawer>
            )}
            {!xs && (
                <PermanentDrawer
                    anchor={anchor}
                    open={isDrawerOpen}
                    onClose={toggleDrawer(anchor, false)}
                    sx={{
                        '& .TemporaryDrawer-paper': { boxSizing: 'border-box' },
                    }}
                    variant="permanent"
                >
                    <Toolbar />
                    <DrawerItems />
                </PermanentDrawer>
            )}
        </Box>
    )
}
