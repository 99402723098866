import { API_BASE_URL } from '../../common/axios'

export const anchor = 'left'
export const drawerWidth = 240

export const environment = (() => {
    if (API_BASE_URL.indexOf('nileworks.io') !== -1) {
        return '本番環境'
    } else if (API_BASE_URL.indexOf('nileworks.net') !== -1) {
        return 'ステージング環境'
    } else if (API_BASE_URL.indexOf('nileworks.org') !== -1) {
        return '開発環境'
    } else if (
        API_BASE_URL.indexOf('localhost') !== -1 ||
        API_BASE_URL.indexOf('127.0.0.1') !== -1
    ) {
        return 'ローカル環境'
    } else {
        return '不明な環境'
    }
})()
