import { Box, CssBaseline, Toolbar } from '@mui/material'
import { ReactNode, FC } from 'react'
import { Header } from './Header'
import { Drawer } from './Drawer'

type LayoutProps = {
    children: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header />
            <Drawer />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
    )
}
